<template lang="pug">
.app
  van-nav-bar(
    title="我 的"
    @click-left="onClickLeft"
    left-text="返回"
    left-arrow)
  div
    div(class="input" style="background-color: #FFFFFF;display: flex;align-items: center;justify-content: space-around;font-size: 16px;    box-sizing: border-box;box-shadow: 0 0.5em 2em -0.7em rgb(64 90 163 / 15%);" id='ln')
      van-radio-group(v-model="radio" direction="horizontal")
        van-radio(name="1") 中 文
        van-radio(name="2") English
    div(class="content" style="width: 100%;")
      div(style="margin-bottom:16px")
        .card(style="width: 100%;background-color: cornflowerblue;border-radius: 4px;box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);box-sizing: border-box;padding: 16px;border: 1px solid transparent;border-bottom: 0;height:150px;padding-top:50px")
          .card-header(style="width: 100%;height: 50px;")
            .card-avatar(style="float: left;margin-right: 16px;")
              div(style="width: 44px; height: 44px; line-height: 44px;")
                img(src="http://qzs.zlogic.cn/yuyue/img/9.png" alt="" style="width: 30px;height: 30px;margin-left: 5px;margin-top: 6px;")
            .card-user-desc(style="width: calc(100% - 78px);float: left;")
              .card-user-name(style="width: 100%;height: 50px;line-height: 38px;font-size: 20px;color: #333;font-weight: 700;")
                div {{ profile.phone }}

</template>

<script>
import { Dialog } from 'vant'

export default {
  data: () => ({
    radio: '1',
    show: false,
    value: '',
    showPicker: false,
    columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
    showPopover: false,
    // 通过 actions 属性来定义菜单选项
    actions: [
      { value: '1', text: '切换工作区' },
      { value: '2', text: '账号登出' },
    ],
    stars: [],
    dataList: [],
    editedIndex: -1,
    deleteAppDialog: false,
    deleteFormDialog: false,
    deleteFormObj: {},
    deleteAppObj: {},
    deleteText: '',
    apps: [],
    count: 0,
    appImgs: [
      'app1.png',
      'app2.png',
      'app3.png',
      'app4.png',
      'app5.png',
      'app6.png',
      'app7.png',
      'app8.png',
      'app9.png',
      'app10.png',
      'app11.png',
      'app12.png',
      'app13.png',
      'app14.png',
      'app15.png',
      'app16.png',
    ],
    formImgs: [
      'form1.png',
      'form2.png',
      'form3.png',
      'form4.png',
      'form5.png',
      'form6.png',
      'form7.png',
      'form8.png',
      'form9.png',
      'form10.png',
      'form11.png',
      'form12.png',
      'form13.png',
      'form14.png',
      'form15.png',
      'form16.png',
      'form17.png',
      'form18.png',
      'form19.png',
      'form20.png',
      'form21.png',
      'form22.png',
      'form23.png',
      'record1.png',
      'record2.png',
      'record3.png',
      'record4.png',
      'record5.png',
      'record6.png',
      'record7.png',
      'record8.png',
      'record9.png',
      'record10.png',
      'record11.png',
      'record12.png',
      'record13.png',
      'record14.png',
      'record15.png',
    ],
    schema_type: [
      { id: 'T01', name: '普通表单' },
      // { id: "T02", name: "流程表单" },
      { id: 'T03', name: '报表' },
    ],
    addFormDialog: false,
    addAppDialog: false,
    addFormImageDialog: false,
    addAppImageDialog: false,
    addFormObj: {
      formId: '',
      formName: '',
      formDesc: '',
      formType: 'T01',
      formAppID: '',
      formPicture: 'form1.png',
    },
    addAppObj: {
      formId: '',
      formName: '',
      formDesc: '',
      formPicture: 'app1.png',
    },
    teams: [],
    defaultIndex: 0,
  }),
  computed: {
    team () {
      return this.$s.getters.team
    },
    profile () {
      return this.$s.getters.profile
    },
    role () {
      return this.$s.getters.role
    },
  },
  watch: {
    $route: function (to, from) {
      console.log('change route')
      this.initData()
    },
    team: function (team) {
      console.log('change team to', team.name)
      this.initData()
    },
  },
  mounted () {
    console.log('index mounted')
  },
  created () {},
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    getUserTeams () {
      this.$api.getUserTeams().then((res) => {
        if (res.status === 200) {
          this.teams = res.data
          this.teams.forEach((item, index) => {
            if (item.id === this.team.id) {
              this.defaultIndex = index
            }
          })
        }
      })
    },
    showPhone () {
      // this.show = true;
      Dialog.confirm({
        title: '联系顾问',
        message: '021-11111111',
        confirmButtonText: '呼叫',
      })
        .then(() => {
          // on confirm
          console.log('确定')
          window.location.href = 'tel://021-11111111'
        })
        .catch(() => {
          // on cancel
          // console.log("确定");
        })
    },
    changeTeam (team) {
      this.$api.changeTeam({ team_id: team.id }).then((res) => {
        if (res.status === 200) {
          const token = res.data.token
          const user = res.data.user
          const team = res.data.default_team

          this.$s.commit('setToken', token)
          this.$s.commit('setProfile', user)
          this.$s.commit('setTeam', team)

          this.showPicker = false
          // this.$router.push({ name: "home" });
        }
      })
    },
    // onConfirm(value) {
    //  console.log(value);
    //  //this.value = value;
    //  this.showPicker = false;
    // },
    // 获取列表
    onSelect (action) {
      // Toast(action.value);
      if (action.value === '1') {
        this.showPicker = true
      } else if (action.value === '2') {
        // this.showPicker = true;
        this.signOut()
      }
    },
    showAddAppDialog () {
      this.addAppObj = {
        formId: '',
        formName: '',
        formDesc: '',
        formPicture: 'app1.png',
      }
      this.addAppDialog = true
    },

    showEditAppDialog (item) {
      this.$set(this.addAppObj, 'formId', item.id)
      this.$set(this.addAppObj, 'formName', item.name)
      this.$set(this.addAppObj, 'formDesc', item.desc)
      this.$set(this.addAppObj, 'formPicture', item.picture)
      this.addAppDialog = true
    },
    showAddFormDialog () {
      this.addFormObj = {
        formId: '',
        formName: '',
        formDesc: '',
        formType: 'T01',
        formAppID: '',
        formPicture: 'form1.png',
      }
      this.addFormDialog = true
    },

    showEditFormDialog (item) {
      this.$set(this.addFormObj, 'formId', item.id)
      this.$set(this.addFormObj, 'formName', item.name)
      this.$set(this.addFormObj, 'formDesc', item.desc)
      this.$set(this.addFormObj, 'formPicture', item.picture)
      this.$set(this.addFormObj, 'formType', item.type)
      this.$set(this.addFormObj, 'formAppID', item.app_id)
      this.addFormDialog = true
    },
    getStarSchema () {
      this.stars = []
      this.$api.getStarForm().then((res) => {
        if (res.status === 200) {
          // this.stars = res.data
          // this.$api.getGroupByPermission().then((res3) => {
          //  if (res3.status === 200) {
          //    res.data.forEach((item) => {
          //      res3.data.forEach((item3) => {
          //        if (item.id === item3.schema_id) {
          //          if (item3.read === 1) {
          //            this.stars.push(item)
          //          }
          //        }
          //      })
          //    })
          //  }
          // })
        }
      })
    },
    star (n, v) {
      // console.log(n);
      n.star = v
      const dataObj = {
        id: n.id,
        star: v,
      }

      this.$api.postStarForm(dataObj).then((res) => {
        if (res.status === 200) {
          this.getStarSchema()
          this.initData()
        }
      })
    },
    addAppImg (v) {
      this.addAppObj.formPicture = v
      this.addAppImageDialog = false
    },
    addFormImg (v) {
      this.addFormObj.formPicture = v
      this.addFormImageDialog = false
    },
    initData () {
      // this.$api.postFormItemReport().then((res) => {
      //  // this.count =
      //  console.log(res.data)
      // })

      // this.$api.postFormItemReportCount().then((res) => {
      //  if (res.status === 200) {
      //    this.count = res.data.count
      //  }
      // })
      this.getStarSchema()
      this.$api.getAppsSchemas({}).then((res) => {
        if (res.status === 200) {
          this.apps = res.data
          console.log(this.apps)
        }
      })
    },

    getListData () {
      this.$api.getForm().then((res) => {
        this.dataList = res.data
      })
    },
    // 编辑表单
    editItem (item, index) {
      if (item.type === 'T03') {
        this.$router.push({
          name: 'form_report_list',
          params: {
            schema_id: item.id,
            app_id: item.app_id,
          },
        })
      } else {
        this.$router.push({
          name: 'form_list',
          params: {
            schema_id: item.id,
            app_id: item.app_id,
          },
        })
      }
    },
    // 弹窗显示
    showDeleteAppDialog (item) {
      this.deleteText = ''
      this.deleteAppObj = item
      this.deleteAppDialog = true
    },

    // 删除表单
    deleteForm () {
      this.$api
        .deleteForm(this.deleteFormObj.id)
        .then((res) => {
          //      this.getListData();
          this.initData()
          this.deleteFormDialog = false
          this.$message({ message: '删除成功', type: 'success' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toDetail (item) {
      this.$router.push({
        name: 'form_design',
        params: {
          id: item.id,
        },
      })
    },
    toDetail2 (item) {
      this.$router.push({
        name: 'form_report',
        params: {
          id: item.id,
          type: 'report',
        },
      })
    },
    openApp () {
      this.$router.push({
        name: 'apps',
      })
    },
    addForm () {
      if (!this.addFormObj.formName || this.addFormObj.formName === '') {
        this.$message({ message: '名称必须填写', type: 'warning' })
        return
      }

      if (!this.addFormObj.formAppID || this.addFormObj.formAppID === '') {
        this.$message({ message: '应用必须选择', type: 'warning' })
        return
      }
      if (this.addFormObj.formId === '') {
        const dataObj = {
          name: this.addFormObj.formName,
          desc: this.addFormObj.formDesc,
          picture: this.addFormObj.formPicture,
          numberId: '',
          extend: [],
          kind: 'kind',
          app_id: this.addFormObj.formAppID,
          config: {
            name: this.addFormObj.formName,
            desc: this.addFormObj.formDesc,
            numberId: '',
            columns: [],
          },
          type: this.addFormObj.formType,
        }

        if (this.addFormObj.formType === 'T03') {
          this.$api.postForm(dataObj).then((res) => {
            if (res.status === 200) {
              // this.$message({ message: '创建成功', type: 'success' })
              const schemaID = res.data.id
              this.$router.push({
                name: 'form_report',
                params: { id: schemaID },
              })
            }
          })
        } else {
          this.$api.postForm(dataObj).then((res) => {
            if (res.status === 200) {
              // this.$message({ message: '创建成功', type: 'success' })
              const schemaID = res.data.id
              this.$router.push({
                name: 'form_design',
                params: { id: schemaID },
              })
            }
          })
        }
      } else {
        const dataObj = {
          id: this.addFormObj.formId,
          name: this.addFormObj.formName,
          desc: this.addFormObj.formDesc,
          picture: this.addFormObj.formPicture,
        }

        this.$api
          .putInfoForm(
            this.addFormObj.formId,
            dataObj,
            this.addFormObj.formAppID
          )
          .then((res) => {
            if (res.status === 200) {
              // const schemaID = res.data.id
              // this.$router.push({
              //  name: "form_report",
              //  params: { id: schemaID },
              // });
              this.addFormDialog = false
              this.initData()
              this.$message({ message: '修改表单成功', type: 'success' })
            }
          })
      }
    },
    addApp () {
      if (!this.addAppObj.formName || this.addAppObj.formName === '') {
        this.$message({ message: '名称必须填写', type: 'warning' })
        return
      }
      if (this.addAppObj.formId === '') {
        const dataObj = {
          name: this.addAppObj.formName,
          desc: this.addAppObj.formDesc,
          picture: this.addAppObj.formPicture,
          numberId: '',
          extend: [],
          kind: 'kind',
          config: {
            name: this.addAppObj.formName,
            desc: this.addAppObj.formDesc,
            numberId: '',
            columns: [],
          },
        }
        this.$api.postApp(dataObj).then((res) => {
          if (res.status === 200) {
            this.addAppDialog = false

            this.initData()
            this.$message({ message: '创建成功', type: 'success' })
          }
        })
      } else {
        const dataObj = {
          id: this.addAppObj.formId,
          name: this.addAppObj.formName,
          desc: this.addAppObj.formDesc,
          picture: this.addAppObj.formPicture,
          numberId: '',
          extend: [],
          kind: 'kind',
          config: {
            name: this.addAppObj.formName,
            desc: this.addAppObj.formDesc,
            numberId: '',
            columns: [],
          },
        }

        this.$api.putApp(dataObj).then((res) => {
          if (res.status === 200) {
            this.addAppDialog = false

            this.initData()
            this.$message({ message: '修改成功', type: 'success' })
          }
        })
      }
    },

    showDeleteFormDialog (item) {
      this.deleteFormObj = item
      this.deleteFormDialog = true
    },

    // 删除表单
    deleteApp () {
      this.$api
        .deleteApps(this.deleteAppObj.id)
        .then((res) => {
          //      this.getListData();
          this.initData()
          this.deleteAppDialog = false
          this.$message({ message: '删除成功', type: 'success' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toSetting (schema) {
      this.showEditFormDialog(schema)
      // if (schema.type !== "T03") {
      //  this.$router.push({ name: "form_design", params: { id: schema.id } });
      // } else if (schema.type === "T03") {
      //  this.$router.push({
      //    name: "form_report",
      //    params: { id: schema.id, type: "report" },
      //  });
      // }
    },
    toDesign (schema) {
      if (schema.type !== 'T03') {
        this.$router.push({ name: 'form_design', params: { id: schema.id } })
      } else if (schema.type === 'T03') {
        this.$router.push({
          name: 'form_report',
          params: { id: schema.id, type: 'report' },
        })
      }
    },
    clickApp (app) {
      console.log('clickApp', app)
      this.$router.push({ name: 'app', params: { app_id: app.id } })
    },
    book () {
      window.open('https://www.yuque.com/kuaidayun/help', '_blank')
    },
    signOut () {
      this.$s.commit('removeToken')
      this.$s.commit('removeProfile')
      this.$s.commit('removeTeam')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile_setting {
  .home-main {
    height: 100%;
    margin: 0 auto;
    .home-main-left {
      display: inline-block;
      width: 250px;
      height: 100px;
      .center {
        height: 100%;
        width: 100%;
        font-size: 24px;
        font-weight: 500;
        align-items: center;
        text-align: center;
      }
    }
    .home-main-right {
      float: right;
      width: calc(100% - 300px);
      height: 100px;
      background: #fff;
      padding: 0 20px;
      border-radius: 8px;
      ul {
        height: 100%;
        width: 100%;
        list-style: none;
        display: inline-flex;
        li {
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: 500;
          width: 33%;
          height: 100%;
          display: inline-flex;
        }
      }
    }
  }

  .container {
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 960px) {
    .container {
      max-width: 900px;
    }
  }
  @media (min-width: 1264px) {
    .container {
      max-width: 1185px;
    }
  }
  @media (min-width: 1904px) {
    .container {
      max-width: 1785px;
    }
  }
  .container--fluid {
    max-width: 100%;
  }
  .app-card {
    width: 235px;
    height: 128px;
    background: #fff;
    margin: 0 16px 16px 0;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgb(12 31 80 / 8%);
    cursor: pointer;
    transition: box-shadow 0.15s ease-out 0s;
  }
  .app-card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .action {
    float: right;
    width: 25%;
    margin: 4px 0;
    text-align: center;
    color: #acb3bd;
    outline: none;
    transition: all 0.3s ease-in-out;
    .el-button {
      padding: 0px;
    }
  }
  .index-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    .user-info {
      display: flex;
      padding: 24px 16px 0;
      width: 100%;
      height: 140px;
      box-sizing: border-box;
      //margin-bottom: -45px;
      margin-bottom: 20px;
      background-color: #fff;
      .layout {
        width: calc(100% - 84px);
        box-sizing: border-box;
        float: left;
        .nickname {
          color: #333;
          font-size: 20px;
          line-height: 28px;
          height: 28px;
          font-weight: 700;
        }
        .tip {
          font-size: 12px;
          line-height: 20px;
          height: 16px;
          color: #666;
        }
      }
    }
    .content {
      flex: 1 1 auto;
      display: block;
      position: relative;
      padding-bottom: 20px;
      overflow: hidden;
      box-sizing: border-box;
      margin-left: 16px;
      margin-right: 16px;
      .card {
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
        box-sizing: border-box;
        padding: 16px;
        border: 1px solid transparent;
        border-bottom: 0;
      }
    }
  }
}
.input {
  width: 80%;
  /* float: left; */
  margin-left: 5%;
  height: 37px;
  line-height: 37px;
  border: 0px;
  color: #333333;
  font-size: 16px;
  background-color: #efefef;
}
.content {
  width: 100%;
}
</style>
